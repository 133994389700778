<template>
  <section class="header_wrap" :class="{ 'with-warning': !localIsExtensionInstalled }">
    <div v-if="!localIsExtensionInstalled" class="extension-warning">
      🚨 확장 프로그램이 아직 설치되지 않았어요! 필요한 기능을 바로 이용하려면 지금 다운로드하세요!
      <a href="https://chromewebstore.google.com/detail/idkahagphdclcilpdofbcapdphnghgli?utm_source=item-share-cb"
         target="_blank"
         @click="startCheckingForExtension">
        <p class="link"> 받으러 가기 ⬇️</p>
      </a>
    </div>

    <div class="side_logo">
      <router-link to="/app">
        <img class="selected" src="/assets/images/common/logo.png" alt="logo" width="130" />
      </router-link>
    </div>

    <div class="header_content">
      <div class="left_side">
        <div class="expire_date">
          <p class="remainTime" v-html="remainTime.replace(/\n/g, '<br />')"></p>
        </div>
      </div>

      <div class="right_side">
        <div class="greeting" v-if="userName"> {{ userName }}님, 환영 합니다. 즐거운 하루 되세요.</div>
        <button class="approval">
          <router-link to="/plan"> 결제하기</router-link>
        </button>
        <button class="balance" @click="openDepositDetail = true">
          <span>포인트</span>
          <p>
            <strong>{{ depositInfo?.deposit.toLocaleString() }}</strong> 원
          </p>
        </button>
        <button class="alert" @click="openAlert()">
          <div class="svg_container">
            <svg_alert />
            <div class="badge" v-if="!alert">.</div>
          </div>
          <!--          <div class="svg_container">
                      <svg_work_status />
                      <div class="badge" v-if="!alert && !visitUpload && socketStore.status !== 'WAIT'">
                        .
                      </div>
                    </div>-->
        </button>
        <button class="logout" @click="setLogout">
          <img src="/assets/images/app/layout/logout.svg" alt="logout" title="로그아웃" />
        </button>
      </div>
    </div>

    <Alert v-if="alert" @closeAlert="closeAlert" />
    <DepositDetail v-if="openDepositDetail" :depositInfo="depositInfo" @closeDepositDetail="closeDepositDetail"
                   v-click-outside="closeDepositDetail" />

    <!-- ✅ Fix: Pass `removeToast` properly -->
    <PccToast :toastList="toastList" @removeToast="removeToast" />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import Alert from '../components/Alert';
import DepositDetail from '../components/DepositDetail';
import PccToast from '../components/PccToast';
import svg_alert from '@/components/svg/svg_alert';
/*
import svg_work_status from '@/components/svg/svg_work_status';
*/
import { accountAPI } from '@/api'; //dashboardApi
import chromeExAPI from '@/chromeExAPI';

export default {
  props: {
    isExtensionInstalled: Boolean
  },
  components: {
    Alert,
    DepositDetail,
    PccToast,
    svg_alert
    /*
        svg_work_status
    */
  },
  data() {
    return {
      remainTime: '확인 중입니다.',
      alert: false,
      visitUpload: false,
      depositInfo: null,
      openDepositDetail: false,
      pcc: '',
      receiverName: '',
      receiverTelNo: '',
      toastList: [],
      userName: '',
      extensionCheckInterval: null,
      localIsExtensionInstalled: this.isExtensionInstalled
    };
  },
  created() {
    this.getDepositInfo();
    if (this.$route.query['ResultCode'] === '3001') {
      this.checkPaymentAfter();
    }
    /*
        setInterval(() => this.updateRemainTime(), 1000);
    */
  },
  async mounted() {
    this.checkExtensionStatus();
    const cookieUserName = this.getCookie('userName');
    if (cookieUserName) {
      this.userName = cookieUserName;
    } else {
      const { user } = this.authStore;
      this.userName = user.userName.trim() ? user.userName : '회원';
      this.setCookie('userName', this.userName, 1);
    }
  },
  beforeDestroy() {
    if (this.extensionCheckInterval) {
      clearInterval(this.extensionCheckInterval);
      this.extensionCheckInterval = null;
    }
  },
  methods: {
    updateRemainTime() {
      const user = this.depositInfo;

      const expireDate = user?.endDate;
      const joinDate = user?.joinDate;

      const now = dayjs();
      const join = dayjs(joinDate);
      const expire = dayjs(expireDate);
      const thresholdDate = dayjs('2025-03-26');

      const freePeriod = join.isAfter(thresholdDate.subtract(1, 'day')) ? 21 : 14;
      const freeEnd = join.add(freePeriod, 'day');               // 무료 끝나는 날
      const paidStart = freeEnd;                                 // 유료 시작일

      console.log('유료 시작일 +' + paidStart.format('YYYY-MM-DD').toString());
      const remainingFreeDays = freeEnd.diff(now, 'day');
      const remainingPaidDays =
       /* now.isBefore(paidStart) ?  expire.diff(paidStart, 'day') :*/ expire.diff(now, 'day');

       if (remainingFreeDays > 0 && now.isBefore(freeEnd) && remainingPaidDays > 0) {
        this.remainTime = `📢 무료 사용기간이 ${remainingFreeDays}일 남았습니다.\n  +  총 사용 기간이 ${remainingPaidDays}일 남았습니다. (정품소명가능) `;
      } else if (remainingFreeDays <= 0 && remainingPaidDays > 0) {
        this.remainTime = `📢 유료 사용기간이 ${remainingPaidDays}일 남았습니다. (정품소명가능)`;
      } else if (remainingFreeDays > 0 && remainingPaidDays > 0) {
        // 오늘이 무료 종료일 당일이면 둘 다 보여줄 수 있음
        this.remainTime = `📢 무료 사용기간이 ${remainingFreeDays}일 남았습니다.\n + 총 사용 기간이 ${remainingPaidDays}일 남았습니다. (정품소명가능)`;
      } else {
        this.remainTime = '📢 사용 기간이 만료되었습니다.';
      }
    },
    async getDepositInfo() {
      const res = await accountAPI.GetUserRestrics();
      this.depositInfo = res.data;
      this.updateRemainTime();
    },
    openAlert() {
      this.alert = !this.alert;
      this.visitUpload = true;
    },
    closeAlert() {
      this.alert = false;
    },
    closeDepositDetail() {
      this.openDepositDetail = false;
    },
    checkPaymentAfter() {
      this.common.setToast('blue', 'good', '포인트 충전이 완료되었습니다.');
      this.openDepositDetail = true;
    },
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    },
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
        if (cookie.indexOf(name + '=') === 0) {
          return cookie.substring(name.length + 1, cookie.length);
        }
      }
      return null;
    },
    deleteCookie(cookieName) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    setLogout() {
      this.authStore.logout();
      this.deleteCookie('userName');
      this.templateStore.clearTemplate();
    },
    async checkExtensionStatus() {
      this.localIsExtensionInstalled = await chromeExAPI.isExtensionInstalled();
      if (this.localIsExtensionInstalled) {
        clearInterval(this.extensionCheckInterval);
        this.extensionCheckInterval = null;
      }
    },
    startCheckingForExtension() {
      if (this.localIsExtensionInstalled) return;
      if (!this.extensionCheckInterval) {
        this.extensionCheckInterval = setInterval(() => {
          this.checkExtensionStatus();
        }, 1000);
      }
    },
    removeToast(index) {
      this.toastList.splice(index, 1);
    }
  }
};
</script>


<style lang="scss">
@import '@/assets/style/app/layout/header.scss';
</style>