<template>
  <div class="deposit_detail">
    <div class="deposit_header">
      <h3 class="title">
        <svg_my_payment />
        <span>포인트 결제</span>
      </h3>
      <button class="close_button" @click="$emit('closeDepositDetail')">
        <svg_close />
      </button>
    </div>
    <div class="deposit_container">
      <div class="setting_row">
        <p class="setting_name">현재 포인트</p>
        <p class="deposit_price">{{ effectiveDepositInfo.deposit.toLocaleString() }} 원</p>
      </div>
      <div class="setting_row">
        <p class="setting_name">현재 마일리지</p>
        <p class="deposit_price">{{ effectiveDepositInfo.mileage.toLocaleString() }} 원</p>
      </div>
      <div class="setting_row recharge_deposit">
        <p class="setting_name">충전 포인트</p>
        <div class="option_group">
          <div class="recharge_price">
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 10000">1만원</button>
            <!--            <button @click="rechargeDeposit = Number(rechargeDeposit) + 30000">3만원</button>-->
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 50000">5만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 100000">10만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 300000">30만원</button>
            <button @click="rechargeDeposit = Number(rechargeDeposit) + 1000000">100만원</button>
            <!--            <button @click="rechargeDeposit = Number(rechargeDeposit) + 300000">30만원</button>
                        <button @click="rechargeDeposit = Number(rechargeDeposit) + 1000000">100만원</button>-->
            <button @click="rechargeDeposit = 0, depositorName = ''">초기화</button>
          </div>
          <div class="input_group">
            <input type="number" v-model="rechargeDeposit" />
            <span class="unit">원</span>
          </div>
        </div>
      </div>
      <div class="setting_row">
        <p class="setting_name">입금자명</p>
        <div class="input_group">
          <input type="text" class="depositor_name" v-model="depositorName" @keyup="checkIfNameValid" />
        </div>
      </div>
      <div class="setting_row">
        <p class="setting_name">충전 후 총 포인트</p>
        <p class="deposit_price">
          {{ (effectiveDepositInfo.deposit + Number(rechargeDeposit)).toLocaleString() }} 원
        </p>
      </div>
    </div>
    <div class="payment_amount">
      <div class="setting_row">
        <p class="setting_name">최종 결제금액</p>
        <p class="deposit_price">{{ rechargeDeposit.toLocaleString() }} 원 </p>
        <p class="deposit_price">(무통장입금)</p>
      </div>
      <div class="radio_group">
        <!--        <div class="text_option">
                  <input type="text" v-model="name" value="입금자명" id="입금자명" />
                  <label for="입금자명">입금자명</label>
                </div>-->
        <!--        <div class="radio_option">
                  <input type="radio" v-model="payType" value="무통장입금" id="무통장입금" checked disabled />
                  <label for="가상계좌">무통장입금</label>
                </div>-->
      </div>
      <div class="button_group">
        <button class="submit_button" @click="createPaymentId">충전하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import svg_my_payment from '@/components/svg/svg_my_payment';
import svg_close from '@/components/svg/svg_close';
import { paymentAPI, accountAPI } from '@/api';

export default {
  components: {
    svg_my_payment,
    svg_close
  },
  props: {
    depositInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      payType: '무통장입금',
      rechargeDeposit: 0,
      depositorName: '',
      localDepositInfo: {}
    };
  },
  async created() {
    const response = await accountAPI.GetUserRestrics();
    if (response.status === 'Success') {
      this.localDepositInfo = response.data;
    }
  },
  computed: {
    effectiveDepositInfo() {
      return Object.keys(this.localDepositInfo).length ? this.localDepositInfo : this.depositInfo;
    }
  },
  methods: {
    async createPaymentId() {
      if (this.rechargeDeposit < 10) {
        this.common.setToast('blue', 'info', '요금을 입력해주세요.');
        return;
      }
      if (!this.checkIfNameValid || !this.depositorName || this.depositorName.trim() === '') {
        this.common.setToast('red', 'info', '입금자명을 정확히 입력해주세요.');
        return;
      }
      this.common.setDialog({
        subTitle: '무통장 입금\n하나은행 904-910015-16404 주식회사 셀리오마켓로\n입금해주세요.',
        button: {
          content: '확인',
          onClick: async () => {
            await this.processPayment();
          }
        }
      });
    },
    async processPayment() {

      const { user } = this.authStore;
      const paymentId = await paymentAPI.CreateDepositPaymentId({
        payType: this.payType,
        price: this.rechargeDeposit,
        depositorName: this.depositorName,
        requestTime: this.getFormattedTime(),
        user: user
      });

      if (paymentId.status === 'Success') {
        this.common.setToast('green', 'info', '충전 신청이 완료 되었습니다.');
      } else {
        this.common.setToast('red', 'info', paymentId.message);
      }


      /*    const { user } = this.authStore;*/

      // eslint-disable-next-line no-undef
      /*      await innopay.goPay({
              PayMethod: this.payType === '카드' ? 'CARD' : 'VBANK',
              MID: 'PG필요',
              MerchantKey: 'PGKEY',
              GoodsName: '셀리오마켓',
              Amt: String(this.rechargeDeposit),
              BuyerName: user.userName,
              BuyerTel: user.userTelNo,
              BuyerEmail: user.userEmail,
              ResultYN: 'Y',
              ReturnURL: `https://omarket.kr${this.$route.fullPath}`,
              MallReserved: paymentId.data
            });*/
    },
    checkIfNameValid() {
      if (!isNaN(this.depositorName)) {
        this.common.setToast('purple', 'info', '이름은 숫자가 될 수 없습니다.');
        this.depositorName = '';
        return false;
      }

      if (this.depositorName.length > 7) {
        this.common.setToast('purple', 'info', '이름은 7글자 이하여야 합니다.');
        return false;
      }
      return true;
    },
    getFormattedTime() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}.${String(now.getMilliseconds()).padStart(3, '0')}`;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/depositDetail.scss';
</style>
