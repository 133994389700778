<template>
  <div id="app">
    <router-view />
    <Loading v-if="common.loading" />
    <Dialog v-if="common.dialog.open" />
    <Toast />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading';
import Dialog from '@/components/common/Dialog';
import Toast from '@/components/common/Toast';
import { useAuthStore } from "@/stores/auth";


export default {
  name: 'App',
  components: {
    Loading,
    Dialog,
    Toast
  },
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  async mounted() {
    this.authStore.restoreSession();
  }
};
</script>

<style lang="scss">
@import './assets/style/globalStyle.scss';
@import 'vue2-datepicker/index.css';
</style>