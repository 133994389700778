import { defineStore } from 'pinia';
import chromeExAPI from '@/chromeExAPI';
export const useCommonStore = defineStore({
  id: 'commonStore',
  state: () => ({
    loading: false,
    dialog: {
      open: false,
      title: null,
      title_size: 24,
      info: null,
      subTitle: null,
      icon: null,
      button: null
    },
    toastList: []
  }),
  actions: {
    async checkExtension() {
      this.isExtensionInstalled = await chromeExAPI.isExtensionInstalled();
      return this.isExtensionInstalled;
    },
    setLoading() {
      if (this.loading) {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
      this.loading = true;
    },
    setDialog({ title, title_size, info, subTitle, icon, button }) {
      this.dialog.open = true;
      this.dialog.title = title;
      this.dialog.title_size = title_size !== undefined ? title_size : 24;
      this.dialog.info = info;
      this.dialog.subTitle = subTitle;
      this.dialog.icon = icon;
      if (button !== undefined) {
        this.dialog.button = {
          content: button.content,
          color: button.color,
          onClick: button.onClick
        };
      }
    },
    closeDialog() {
      this.dialog.open = false;
      this.dialog.title = null;
      this.title_size = 24;
      this.info = null;
      this.dialog.subTitle = null;
      this.dialog.icon = null;
      this.dialog.button = null;
    },
    setToast(color, icon, text) {
      const existToast = this.toastList.find(toast => toast.text === text);
      if (existToast !== undefined) {
        return;
      }
      this.toastList.push({ color, icon, text });
      setTimeout(() => {
        this.toastList.shift();
      }, 5000);
    },
    sleep(sec) {
      return new Promise(resolve => setTimeout(resolve, sec * 100));
    },
    openLink(url){
      window.open(url, 'blank');
    }
  }
});
