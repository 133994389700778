import { defineStore } from 'pinia';
import router from '@/router';
import { authAPI } from '@/api';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    user: null,
    encodeData: null,
    userName: null,
    userPhone: null
  }),
  actions: {
    refreshToken: '',
    async setupUser() {
      let response = await authAPI.GetUser();
      if (response.status === 'Success') {
        this.user = response.data;
      }
      return this.user;
    },

    async login(userId, password) {
      const responseModel = await authAPI.Login({
        userId: userId,
        password: password
      });
      if (responseModel.status === 'Success') {
        this.user = responseModel.data;
        this.accessToken = responseModel.data.accessToken;
        this.refreshToken = responseModel.data.refreshToken;

        localStorage.setItem('user', JSON.stringify(this.user)); // 2025-02-21 추가 - 새로고침시 유저정보 누락 현상 제거 
        localStorage.setItem('accessToken', this.accessToken);
        localStorage.setItem('refreshToken', this.refreshToken);
      }
      return responseModel;
    },

    logout() {
      this.accessToken = null;
      this.refreshToken = null;
      this.user = null;

      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      if (router.history.current.fullPath !== '/user') {
        router.push('/user');
      }
    },
    restoreSession() {  // 2025-02-21 추가 - 새로고침시 유저정보 누락 현상 제거
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        this.user = JSON.parse(storedUser);
        this.accessToken = localStorage.getItem("accessToken");
        this.refreshToken = localStorage.getItem("refreshToken");
      }
    },
    getStoredUser() {
      try {
        return JSON.parse(localStorage.getItem("user")) || null;
      } catch (error) {
        console.error("Failed to parse stored user:", error);
        return null;
      }
    },
    setEncodeData(data) {
      this.encodeData = data;
      router.push('/user/join');
    },
    setUserInfo(info) {
      this.userName = info.name;
      this.userPhone = info.phone;
    }
  }
});
