import { JwtAxios } from '@/packaging';

export const collectApi = {
  async GetCollectGroupNameList(site) {
    const responseModel = await JwtAxios.get(`/Collect/GetCollectGroupNameList?site=` + site);
    return responseModel;
  },
  async SetCollectGroup(model) {
    const responseModel = await JwtAxios.post(`/Collect/SetCollectGroup`, model);
    return responseModel;
  },
  async GetCollectGroupList(model) {
    const responseModel = await JwtAxios.post(`/Collect/GetCollectGroupList`, model);
    return responseModel;
  },
  async GetCollectGroup(groupName) {
    const responseModel = await JwtAxios.get(`/Collect/GetCollectGroup?groupName=${groupName}`);
    return responseModel;
  },
  async GetLastCollectGroup(model) {
    const responseModel = await JwtAxios.get('/Collect/GetLastCollectGroup', model);
    return responseModel;
  },
  async RemoveCollectGroup(model) {
    const responseModel = await JwtAxios.delete(`/Collect/RemoveCollectGroup`, model);
    return responseModel;
  },
  async DoCollect(id, model) {
    const responseModel = await JwtAxios.post(`/Collect/DoCollect?id=${id}`, model);
    return responseModel;
  },
  async GetCollectFailLog(model) {
    const responseModel = await JwtAxios.post(`/Collect/GetCollectFailLog`, model);
    return responseModel;
  },
  async UpdateCollectGroup(guid, body) {
    const res = await JwtAxios.patch(`/Collect/UpdateCollectGroup?guid=${guid}`, body);
    return res;
  },
  async UpdateCollectCategoryGroup(guid, body) {
    const res = await JwtAxios.patch(`/Collect/UpdateCollectGroupCategory?guid=${guid}`, body);
    return res;
  },
  async updateBrandCollectGroup(id, body) {
    const res = await JwtAxios.post(`/Collect/LuxItemListInsert?id=${id}`, body);
    return res;
  },
  async VulkUpdateCollectGroup(model) {
    const responseModel = await JwtAxios.patch('/Collect/VulkUpdateCollectGroup', model);
    return responseModel;
  },
  async SearchNaverAdvertiseKeyword(model) {
    const responseModel = await JwtAxios.get('/Collect/SearchNaverAdvertiseKeyword', model);
    return responseModel;
  },
  async SearchNaverShoppingKeyword(model) {
    const responseModel = await JwtAxios.get('/Collect/SearchNaverShoppingKeyword', model);
    return responseModel;
  },
  async VulkDelete(body) {
    const res = await JwtAxios.delete('/Collect/VulkDelete', body);
    return res;
  },
  async VulkCollect(id, body) {
    const res = await JwtAxios.post(`/Collect/VulkCollect?id=${id}`, body);
    return res;
  },
  async TextTranslate(connectionId, body) {
    const res = await JwtAxios.post(`/Collect/TextTranslate?connectionId=${connectionId}`, body);
    return res;
  }
};
