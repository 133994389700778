import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueYoutube from 'vue-youtube';
import globalMixins from './mixin/globals';
import vueWsEcho from './packaging/vueWsEcho';

import SVGComponent from '@/components/common/SVGComponent';
import Title from '@/components/common/Title';
import Pagination from '@/components/common/Pagination';
import DropdownInput from '@/components/common/DropdownInput';
import vClickOutside from 'v-click-outside';

import 'vue2-datepicker/locale/ko.js';

Vue.config.productionTip = false;
const pinia = createPinia();

Vue.component('SVGComponent', SVGComponent);
Vue.component('Title', Title);
Vue.component('Pagination', Pagination);
Vue.component('DropdownInput', DropdownInput);


Vue.use(VueYoutube)
  .use(PiniaVuePlugin)
  .use(pinia)
  .use(globalMixins)
  .use(vClickOutside)
  .use(vueWsEcho);

new Vue({
  router: router,
  render: h => h(App),
  pinia
}).$mount('#app');
