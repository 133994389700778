<template>
  <footer>
    <div class="footer_container">
      <div class="others_info">
        <p>주식회사 셀리오마켓</p>
        <p>대표: 이광성, 심덕은 </p>
        |
        <p>사업자명: 주식회사 셀리오마켓</p>
        |
        <p>사업자번호: 479-87-02239</p>
        |
        <p>주소: 서울특별시 강서구 양천로47길 20 놀라움마곡 212,213호</p>
        |
        <p>통신판매업: 제2021-서울강서-3181호</p>
        |
        <p>Copyright 2024. omarket. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  mounted() {
    (function() {
      var w = window;
      if (w.ChannelIO) {
        return w.console.error('ChannelIO script included twice.');
      }
      var ch = function() {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;

      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        var x = document.getElementsByTagName('script')[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }

      if (document.readyState === 'complete') {
        l();
      } else {
        w.addEventListener('DOMContentLoaded', l);
        w.addEventListener('load', l);
      }
    })();
    window.ChannelIO('boot', {
      pluginKey: 'f194d881-b146-469c-8d41-15ddc5151414'
    });
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/footer.scss';
</style>
