import axios from 'axios';
import { useAuthStore } from '@/stores/auth';

let API_URL, BRAND_API_URL;

if (['https://omarket.kr', 'http://localhost:8080'].includes(window.location.origin)) {
  API_URL = process.env.VUE_APP_PRODUCT_API_URL;
  BRAND_API_URL = process.env.VUE_APP_BRAND_PRODUCT_API_URL;
} else {
  API_URL = process.env.VUE_APP_TEST_API_URL;
  BRAND_API_URL = process.env.VUE_APP_BRAND_PRODUCT_API_URL;
}

export const JwtAxios = {
  get: request('GET', API_URL),
  post: request('POST', API_URL),
  delete: request('DELETE', API_URL),
  put: request('PUT', API_URL),
  patch: request('PATCH', API_URL),
  download: request('DOWNLOAD', API_URL),
  upload: multipartUpload('POST', API_URL),
  excelMultiPartGet: excelMultiPartGet('GET', API_URL),
  excelMultiPartPost: multipartRequest('POST', API_URL)
};

export const BrandJwtAxios = {
  get: request('GET', BRAND_API_URL),
  post: request('POST', BRAND_API_URL),
  delete: request('DELETE', BRAND_API_URL),
  put: request('PUT', BRAND_API_URL),
  patch: request('PATCH', BRAND_API_URL),
  download: request('DOWNLOAD', BRAND_API_URL),
  upload: multipartUpload('POST', BRAND_API_URL),
  excelMultiPartGet: multipartRequest('GET', BRAND_API_URL),
  excelMultiPartPost: multipartRequest('POST', BRAND_API_URL)
};

function request(method, baseURL) {
  return (path, body) => {
    let url = baseURL + path;
    const requestOptions = {
      headers: authHeader(),
      responseType: 'json'
    };
    if (method === 'GET' && body) {
      url += '?' + jsonToQuery(body);
    } else if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)) {
      if (body instanceof FormData) {
        delete requestOptions.headers['Content-Type'];
      } else {
        requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
        requestOptions.body = JSON.stringify(body);
      }
    }
    return axios({
      method,
      url,
      data: body,
      headers: requestOptions.headers,
      responseType: requestOptions.responseType
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function multipartRequest(method, baseURL) {
  return (path, fd) => {
    let url = baseURL + path;
    const requestOptions = { headers: authHeader() };
    requestOptions.headers['accept'] = 'text/plain';
    requestOptions.headers['Content-Type'] = 'application/json';

    return axios({
      method,
      url,
      data: fd,
      headers: requestOptions.headers,
      responseType: 'blob'
    })
      .then(response => {
        const blob = response.data;
        const disposition = response.headers['content-disposition'];
        let fileName = 'download.xlsx';
        if (disposition && disposition.indexOf('filename=') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
        return blob;
      })
      .catch(handleError);
  };
}

function excelMultiPartGet(baseURL) {
  return (path)=> {
    const url = baseURL + path;
    const requestOptions = {
      headers: authHeader()
    };
    requestOptions.headers['accept'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    requestOptions.headers['Content-Type'] =  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return axios({
      method: 'GET',
      url: url,
      headers: requestOptions.headers,
      responseType: 'blob'
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function multipartUpload(method, baseURL) {
  return async (path, fd) => {
    let url = baseURL + path;
    return axios({
      method,
      url,
      data: fd,
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function authHeader() {
  let resultModel = {};
  const { accessToken } = useAuthStore();
  if (accessToken) {
    resultModel = { Authorization: `Bearer ${accessToken}` };
  }
  return resultModel;
}

function handleResponse(response) {
  if (response.status !== 200) {
    const { refreshToken, logout } = useAuthStore();
    if ([401, 403].includes(response.status) && refreshToken) {
      logout();
      return;
    }
    const error = (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  if (response.config.responseType === 'blob') {
    return response;
  }
  return response.data;
}

function handleError(error) {
  const response = error.response;
  if (!response) return false;

  if ([401, 403].includes(response.status)) {
    const { logout } = useAuthStore();
    logout();
    return;
  }
  return response.data;
}

function jsonToQuery(param) {
  if (typeof param !== 'object') return false;
  return Object.keys(param)
    .map(key => `${key}=${encodeURIComponent(param[key])}`)
    .join('&');
}


